import axios from 'axios';

import { API_URL } from '../../router/constants/constants';
import { PaginatedData } from '../../types/dto/common.dto';
import {
  CreateUpdateOtherExpenseDto,
  GetOtherExpensesDto,
  GetWorksheetMetaDto,
  GetWorksheetNotesEventsDto,
  GetWorksheetsDto,
  OtherExpensesDto,
  WorksheetDto,
  WorksheetEventDto,
  WorksheetMetaDto,
  WorksheetNoteCreateUpdateDto,
  WorksheetNoteDto,
} from '../../types/dto/worksheet.dto';
import { WorkSheetStatus } from '../../types/enum/worksheet-status';

export class Worksheets {
  async getWorksheets(
    getWorksheetsDto: GetWorksheetsDto
  ): Promise<PaginatedData<WorksheetDto[]>> {
    const { status, page, pageSize, worksheetNr, lecturerPin } =
      getWorksheetsDto;
    const params = new URLSearchParams();

    params.append('page', page.toString());
    params.append('pageSize', pageSize.toString());

    if (status) {
      status.forEach((s) => {
        params.append('status[]', s);
      });
    }

    if (worksheetNr) {
      params.append('worksheetNr', worksheetNr.toString());
    }

    if (lecturerPin) {
      params.append('lecturerPin', lecturerPin);
    }

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/worksheets`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async getWorksheetMeta(
    getWorksheetMeta: GetWorksheetMetaDto
  ): Promise<PaginatedData<WorksheetMetaDto[]>> {
    const { page, pageSize, worksheetId } = getWorksheetMeta;
    const params = new URLSearchParams();

    params.append('worksheetId', worksheetId);
    params.append('page', page.toString());
    params.append('pageSize', pageSize.toString());

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/worksheets/meta`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async getWorksheet(worksheetId: string): Promise<WorksheetDto> {
    const params = new URLSearchParams();
    params.append('worksheetId', worksheetId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/worksheets/one`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async withdrawWorksheet(worksheetId: string, currentStatus: WorkSheetStatus) {
    const params = new URLSearchParams();
    params.append('worksheetId', worksheetId);
    params.append('currentStatus', currentStatus.toString());

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/admin/worksheets/withdraw`,
      responseType: 'json',
      params,
    });
  }

  async approveWorksheetByProgramCoordinator(worksheetId: string) {
    const params = new URLSearchParams();
    params.append('worksheetId', worksheetId);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/admin/worksheets/approve-pc`,
      responseType: 'json',
      params,
    });
  }

  async approveWorksheetByAccounting(worksheetId: string) {
    const params = new URLSearchParams();
    params.append('worksheetId', worksheetId);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/admin/worksheets/approve-accounting`,
      responseType: 'json',
      params,
    });
  }

  async rejectWorksheetByProgramCoordinator(
    worksheetId: string,
    finalize: boolean
  ) {
    const params = new URLSearchParams();
    params.append('worksheetId', worksheetId);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/admin/worksheets/reject-pc`,
      responseType: 'json',
      params,
      data: { finalize },
    });
  }

  async rejectWorksheetByAccounting(worksheetId: string, finalize: boolean) {
    const params = new URLSearchParams();
    params.append('worksheetId', worksheetId);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/admin/worksheets/reject-accounting`,
      responseType: 'json',
      params,
      data: { finalize },
    });
  }

  async getWorksheetEvents(
    getWorksheetEventsDto: GetWorksheetNotesEventsDto
  ): Promise<PaginatedData<WorksheetEventDto[]>> {
    const { page, pageSize, worksheetId } = getWorksheetEventsDto;
    const params = new URLSearchParams();
    params.append('worksheetId', worksheetId);
    params.append('page', page.toString());
    params.append('pageSize', pageSize.toString());

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/worksheets/events`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async getWorksheetEvent(eventId: string): Promise<WorksheetEventDto> {
    const params = new URLSearchParams();
    params.append('eventId', eventId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/worksheets/events/one`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async getNotes(
    getWorksheetNotesDto: GetWorksheetNotesEventsDto
  ): Promise<PaginatedData<WorksheetNoteDto[]>> {
    const { page, pageSize, worksheetId } = getWorksheetNotesDto;
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('pageSize', pageSize.toString());
    params.append('worksheetId', worksheetId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/worksheets/notes`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async createNote(
    worksheetId: string,
    createNoteDto: WorksheetNoteCreateUpdateDto
  ) {
    const params = new URLSearchParams();
    params.append('worksheetId', worksheetId);

    return await axios({
      method: 'POST',
      url: `${API_URL}/api/admin/worksheets/notes`,
      responseType: 'json',
      params,
      data: createNoteDto,
    });
  }

  async updateNote(noteId: string, noteContent: WorksheetNoteCreateUpdateDto) {
    const params = new URLSearchParams();
    params.append('noteId', noteId);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/admin/worksheets/notes`,
      responseType: 'json',
      data: noteContent,
      params,
    });
  }

  async deleteNote(noteId: string) {
    const params = new URLSearchParams();
    params.append('noteId', noteId);

    return await axios({
      method: 'DELETE',
      url: `${API_URL}/api/admin/worksheets/notes`,
      responseType: 'json',
      params,
    });
  }

  async getOtherExpenses(
    getOtherExpensesDto: GetOtherExpensesDto
  ): Promise<PaginatedData<OtherExpensesDto[]>> {
    const params = new URLSearchParams();
    params.append('page', getOtherExpensesDto.page.toString());
    params.append('pageSize', getOtherExpensesDto.pageSize.toString());
    params.append('worksheetId', getOtherExpensesDto.worksheetId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/worksheets/other-expense`,
      responseType: 'json',
      params,
    });

    return response.data;
  }

  async getOtherExpenseAttachment(binaryId: string) {
    const params = new URLSearchParams();
    params.append('binaryId', binaryId);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/worksheets/other-expense/binary`,
      responseType: 'blob',
      params,
    });

    return response.data;
  }

  async updateOtherExpense(
    otherId: string,
    updateOtherExpenseDto: CreateUpdateOtherExpenseDto
  ) {
    const params = new URLSearchParams();
    params.append('otherId', otherId);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/admin/worksheets/other-expense`,
      responseType: 'json',
      params,
      data: updateOtherExpenseDto,
    });
  }
}
