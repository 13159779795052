import axios from 'axios';

import { API_URL } from '../../router/constants/constants';
import { PaginatedData } from '../../types/dto/common.dto';
import { CreateUserDto, GetUsersDto, UserDto } from '../../types/dto/user.dto';
import { UserRoles } from '../../types/enum/user-roles';

export class User {
  async fetchUser(): Promise<UserDto> {
    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/users`,
      responseType: 'json',
      withCredentials: true,
    });
    return response.data;
  }

  async getUsers(getUsersDto: GetUsersDto): Promise<PaginatedData<UserDto[]>> {
    const { page, perPage, search, userRole } = getUsersDto;
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('perPage', perPage.toString());

    if (search) {
      params.append('search', search);
    }
    if (userRole) {
      params.append('userRole', userRole);
    }

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/users`,
      responseType: 'json',
      withCredentials: true,
      params,
    });
    return response.data;
  }

  async getUsersFlat(getUsersFlatDto: {
    search?: string;
    userRole?: UserRoles;
  }): Promise<UserDto[]> {
    const { search, userRole } = getUsersFlatDto;
    const params = new URLSearchParams();
    if (search) {
      params.append('search', search);
    }
    if (userRole) {
      params.append('userRole', userRole);
    }

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/users/flat`,
      responseType: 'json',
      params,
    });
    return response.data;
  }

  async getUser(userPin: string): Promise<UserDto> {
    const params = new URLSearchParams();
    params.append('userPin', userPin);

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/users/user`,
      responseType: 'json',
      params,
    });
    return response.data;
  }

  async updateSecurityPin(userPin: string, newSecurityPin: string) {
    const params = new URLSearchParams();
    params.append('userPin', userPin);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/admin/users/securityPin`,
      responseType: 'text',
      data: { newSecurityPin },
      params,
    });
  }

  async updateUser(updateUserValues: UserDto) {
    const params = new URLSearchParams();
    params.append('userPin', updateUserValues.pin);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/admin/users/user`,
      responseType: 'text',
      data: updateUserValues,
      params,
    });
  }

  async updateUserRoles(userPin: string, userRoles: any) {
    const params = new URLSearchParams();
    params.append('userPin', userPin);
    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/admin/users/roles`,
      responseType: 'text',
      data: { roles: userRoles },
      params,
    });
  }

  async createUser(createUserDto: CreateUserDto) {
    return await axios({
      method: 'POST',
      url: `${API_URL}/api/admin/users`,
      data: createUserDto,
    });
  }

  async setPassword(setPasswordDto: {
    password: string;
    activationToken: string;
  }) {
    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/users/set-password`,
      data: setPasswordDto,
    });
  }

  async changePassword() {
    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/users/change-password`,
    });
  }

  async sendResetPasswordLink(email: string) {
    const params = new URLSearchParams();
    params.append('email', email);

    return await axios({
      method: 'PATCH',
      url: `${API_URL}/api/users/send-reset-password-link`,
      params,
    });
  }

  async getEnrolledStudents(
    page: number,
    perPage: number,
    programId: string,
    academicYear: number,
    search?: string
  ): Promise<PaginatedData<UserDto[]>> {
    const params = new URLSearchParams();
    params.append('page', page.toString());
    params.append('perPage', perPage.toString());
    params.append('programId', programId);
    params.append('academicYear', academicYear.toString());

    if (search) {
      params.append('search', search);
    }

    const response = await axios({
      method: 'GET',
      url: `${API_URL}/api/admin/users/program-students`,
      responseType: 'json',
      params,
    });

    return response.data;
  }
}
