import {
  Button,
  Card,
  DatePicker,
  Flex,
  Form,
  Input,
  Select,
  Switch,
  notification,
} from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Server } from '../../../../api/server-index';
import { AuthContext } from '../../../../context/auth-context';
import { SelectOptions } from '../../../../types/common/select-options.type';
import { Location } from '../../../../types/enum/location';
import BackButton from '../../../common/buttons/back-button.component';
import LecturerSelect from '../../../common/select/lecturer-select.component';
import Subtitle from '../../../common/titles/subtitle.component';
import { formItemLayout } from '../../../layout/form/form-item.layout';

const NewEvent = () => {
  const { t } = useTranslation(['events', 'common']);
  const navigate = useNavigate();
  const location = useLocation();
  const { currentAcademicYear } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [subjectOptions, setSubjectOptions] = useState<SelectOptions[]>([]);
  const [selectedLecturerPin, setSelectedLecturerPin] = useState<string>('');
  const [isLifelong, setIsLifelong] = useState(false);
  const [form] = Form.useForm();

  const handleLecturerChange = async (value: string) => {
    setSelectedLecturerPin(value);
    const response = await Server.Subjects.getSubjects({
      academicYear: currentAcademicYear,
      lecturerPin: value,
    });
    const lecturerSubjects = response.records;

    const subjectOptions = lecturerSubjects.map((subject) => {
      const subjectName = subject.subjectName;
      const subjectNameEn = subject.subjectNameEn
        ? `(${subject.subjectNameEn})`
        : '';

      return {
        value: subject.id,
        label: `${subjectName} ${subjectNameEn}`,
      };
    });
    setSubjectOptions(subjectOptions);
  };

  const handleSubmit = async (values: any) => {
    setLoading(true);
    const response = await Server.Events.createEvent(values.lecturer, {
      subjectName: values.subjectName,
      dateStart: values.dateStart.toISOString(),
      dateEnd: values.dateEnd.toISOString(),
      notes: values.notes,
      classroom: values.classroom,
      subject: values.subject,
      location: values.location,
      international: values.international,
      webinar: values.webinar,
      lifelong: values.lifelong,
    });

    if (response.status === 201) {
      notification.success({
        message: t('create_update_event.create_success'),
      });
      navigate('/events');
    }

    setLoading(false);
  };

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <Card className='shadow-sm'>
      <BackButton href={`/events${location?.search}`} />
      <Subtitle text={t('create_update_event.create_title')} />
      <Form
        form={form}
        name='edit-event'
        className='mt-2'
        onFinish={handleSubmit}
        {...formItemLayout}
      >
        <Form.Item
          label={t('create_update_event.form.subject_name')}
          name='subjectName'
          rules={[
            { required: true, message: t('create_update_event.form.required') },
          ]}
          validateTrigger='onBlur'
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t('create_update_event.form.start')}
          name='dateStart'
          rules={[
            {
              required: true,
              message: t('create_update_event.form.required'),
            },
          ]}
          validateTrigger='onBlur'
        >
          <DatePicker
            showTime
            format={'DD.MM.YYYY. HH:mm'}
            placeholder=''
            className='w-100'
          />
        </Form.Item>
        <Form.Item
          label={t('create_update_event.form.end')}
          name='dateEnd'
          rules={[
            {
              required: true,
              message: t('create_update_event.form.required'),
            },
          ]}
          validateTrigger='onBlur'
        >
          <DatePicker
            showTime
            format={'DD.MM.YYYY. HH:mm'}
            placeholder=''
            className='w-100'
          />
        </Form.Item>
        <Form.Item
          label={t('create_update_event.form.lecturer')}
          name='lecturer'
          rules={[
            {
              required: true,
              message: t('create_update_event.form.required'),
            },
          ]}
          validateTrigger='onBlur'
        >
          <LecturerSelect
            selectedLecturerPin={selectedLecturerPin}
            showSearch={true}
            onChange={handleLecturerChange}
          />
        </Form.Item>
        <Form.Item label={t('create_update_event.form.subject')} name='subject'>
          <Select
            showSearch
            filterOption={filterOption}
            options={subjectOptions}
            disabled={isLifelong}
          />
        </Form.Item>

        <Form.Item
          label={t('create_update_event.form.classroom')}
          name='classroom'
        >
          <Input />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: t('create_update_event.form.required'),
            },
          ]}
          label={t('create_update_event.form.location.title')}
          name={'location'}
        >
          <Select
            showSearch
            options={Object.values(Location).map((option) => ({
              label: t(`create_update_event.form.location.${option}`),
              value: option,
            }))}
          />
        </Form.Item>
        <Form.Item label={t('create_update_event.form.notes')} name='notes'>
          <TextArea maxLength={240} showCount />
        </Form.Item>

        <Form.Item
          label={t('create_update_event.form.intl')}
          name='international'
          tooltip={t('create_update_event.form.intl_tip')}
          labelCol={{ xs: 21, md: 6 }}
        >
          <Switch />
        </Form.Item>

        <Form.Item
          label={t('create_update_event.form.lifelong')}
          name='lifelong'
          tooltip={t('create_update_event.form.lifelong_tip')}
          labelCol={{ xs: 21, md: 6 }}
        >
          <Switch
            checked={isLifelong}
            onChange={(value) => {
              setIsLifelong(value);
              if (value) {
                form.setFieldsValue({ subject: undefined });
              }
            }}
          />
        </Form.Item>

        <Form.Item
          label={t('create_update_event.form.webinar')}
          name='webinar'
          tooltip={t('create_update_event.form.webinar_tip')}
          labelCol={{ xs: 21, md: 6 }}
        >
          <Switch />
        </Form.Item>

        <Flex justify='flex-end' className='mt-2'>
          <Form.Item>
            <Button htmlType='submit' loading={loading} type='primary' ghost>
              {t('create_update_event.form.submit')}
            </Button>
          </Form.Item>
        </Flex>
      </Form>
    </Card>
  );
};

export default NewEvent;
