import { InfoCircleOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Flex,
  Form,
  Input,
  Select,
  Switch,
  Tooltip,
  notification,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../api/server-index';
import { UserDto } from '../../../../../types/dto/user.dto';
import { UserRoles } from '../../../../../types/enum/user-roles';
import { formItemLayout } from '../../../../layout/form/form-item.layout';

interface Props {
  user: UserDto;
  editUser: boolean;
  setReload: Dispatch<SetStateAction<boolean>>;
}

export function EditUser({ user, editUser, setReload }: Props) {
  const { t } = useTranslation(['user', 'common']);
  const [userForm] = useForm<UserDto>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const loadUser = async () => {
      setLoading(true);
      userForm.setFieldsValue(user);
      userForm.setFieldValue('roles', user.roles);
      setLoading(false);
    };
    loadUser();
  }, [user, userForm]);

  const updateUser = async (updateUserValues: any) => {
    if (user) {
      try {
        setLoading(true);
        const responseUpdate = await Server.User.updateUser(updateUserValues);
        if (
          updateUserValues.roles?.length &&
          updateUserValues.roles?.length !== user?.roles?.length
        ) {
          await Server.User.updateUserRoles(user?.pin, updateUserValues.roles);
        }
        userForm.setFieldsValue(updateUserValues);

        if (responseUpdate.status === 200) {
          notification.success({ message: t('edit.success') });
        }
      } finally {
        setLoading(false);
        setReload(true);
      }
    }
  };

  return (
    <Card loading={loading} className='shadow-sm'>
      <Flex gap={10} align='center'>
        {user.isvuId ? (
          <Tooltip title={t('edit.tooltip')}>
            <InfoCircleOutlined />
          </Tooltip>
        ) : (
          <></>
        )}
      </Flex>
      <Form
        form={userForm}
        disabled={!editUser}
        onFinish={updateUser}
        {...formItemLayout}
      >
        <Form.Item name='pin' label={t('form.pin')}>
          <Input disabled />
        </Form.Item>
        <Form.Item name='hrEduUid' label={t('form.hrEduUid')}>
          <Input disabled />
        </Form.Item>
        <Form.Item name='givenName' label={t('form.givenName')}>
          <Input disabled={!!user.isvuId} />
        </Form.Item>
        <Form.Item name='familyName' label={t('form.familyName')}>
          <Input disabled={!!user.isvuId} />
        </Form.Item>
        <Form.Item name='email' label={t('form.email')}>
          <Input disabled={!!user.isvuId} />
        </Form.Item>
        <Form.Item name={['roles']} label={t('form.roles')}>
          <Select
            mode='multiple'
            allowClear
            options={Object.values(UserRoles).map((option) => {
              return {
                key: option,
                value: option,
                label: t(`roles.${option}`),
              };
            })}
          ></Select>
        </Form.Item>
        {user.roles?.length === 1 && user.roles[0] === UserRoles.STUDENT ? (
          <></>
        ) : (
          <Form.Item name={'isEmployee'} label={t('form.isEmployee')}>
            <Switch value={!!user.isEmployee} />
          </Form.Item>
        )}
        <Flex justify='flex-end'>
          <Button type={'primary'} ghost onClick={userForm.submit}>
            {t('common:save')}
          </Button>
        </Flex>
      </Form>
    </Card>
  );
}
