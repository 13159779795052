import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Empty,
  Flex,
  Form,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Table,
  TableProps,
  Tooltip,
  notification,
} from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { Server } from '../../../../../api/server-index';
import { CompensationDto } from '../../../../../types/dto/compensation.dto';
import { EventType } from '../../../../../types/enum/event-type';
import NewButton from '../../../../common/buttons/new-button.component';

export function Compensation() {
  const { t } = useTranslation(['compensation', 'common']);
  const [loading, setLoading] = useState<boolean>(false);
  const { userPin } = useParams<{ userPin: string }>();
  const compensationTypeArray = useRef(Object.values(EventType));
  const [compensations, setCompensations] = useState<CompensationDto[]>([]);
  const [addCompensationModal, setAddCompensationModal] =
    useState<boolean>(false);
  const [selectedCompensation, setSelectedCompensation] = useState<{
    type: EventType;
    value: number;
  }>({ type: compensationTypeArray.current[0], value: 0 });
  const [edit, setEdit] = useState<{ key: string; bool: boolean }>({
    key: '',
    bool: false,
  });

  const fetchCompensation = useCallback(async () => {
    if (userPin) {
      setLoading(true);
      const __compensations = await Server.Compensation.getCompensation(
        userPin
      );
      if (__compensations.length) {
        compensationTypeArray.current = compensationTypeArray.current.filter(
          (type) =>
            !__compensations.some(
              (compensation) => compensation.activityType === type
            )
        );
        setSelectedCompensation({
          type: compensationTypeArray.current[0],
          value: 0,
        });
      }
      setCompensations(
        __compensations.sort((a, b) => {
          if (t(`types.${a.activityType}`) < t(`types.${b.activityType}`)) {
            return -1;
          } else {
            return 1;
          }
        })
      );
      setLoading(false);
    }
  }, [userPin, t]);

  useEffect(() => {
    fetchCompensation();
  }, [fetchCompensation]);

  const columns: TableProps<CompensationDto>['columns'] = [
    {
      key: 'date',
      title: t('type'),
      dataIndex: 'activityType',
      render: (type: EventType) => <span>{t(`types.${type}`)}</span>,
    },
    {
      key: 'value',
      title: t('value'),
      width: 200,
      render: (value: CompensationDto) =>
        edit.bool && edit.key === value.id ? (
          <InputNumber
            decimalSeparator=','
            defaultValue={value.compensation}
            addonAfter='€'
            onChange={(val) => {
              if (val) {
                setSelectedCompensation((prevVal) => ({
                  ...prevVal,
                  value: val!,
                }));
              }
            }}
          />
        ) : (
          <h4>
            {value.compensation.toString().replace('.', ',')}{' '}
            {`€/${t('school_hour')}`}
          </h4>
        ),
    },
    {
      key: 'actions',
      title: t('actions'),
      width: 150,
      render: (val: CompensationDto) =>
        edit.bool && edit.key === val.id ? (
          <>
            {' '}
            <Button type='link' onClick={submitCompensation}>
              {t('common:save')}
            </Button>
            <Button
              type={'link'}
              danger
              onClick={() => setEdit({ key: '', bool: false })}
            >
              {t('common:cancel')}
            </Button>
          </>
        ) : (
          <Flex gap={12}>
            <Tooltip title={t('editCompensation')}>
              <Button
                onClick={() => {
                  setEdit({ key: val.id, bool: true });
                  setSelectedCompensation({
                    type: val.activityType,
                    value: val.compensation,
                  });
                }}
              >
                <EditOutlined />
              </Button>
            </Tooltip>
            <Popconfirm
              title={t('delete.title')}
              onConfirm={() => handleDeleteCompensation(val.id)}
              okText={t('delete.ok')}
              cancelText={t('delete.cancel')}
            >
              <Button danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Flex>
        ),
    },
  ];

  const handleDeleteCompensation = async (id: string) => {
    const response = await Server.Compensation.deleteCompensation(id);

    if (response.status === 200) {
      notification.success({ message: t('delete.success') });
      fetchCompensation();
    }
  };

  const submitCompensation = async () => {
    if (userPin) {
      setLoading(true);
      const __compensation = compensations.find(
        (compensation) =>
          compensation.activityType === selectedCompensation.type
      );
      if (__compensation) {
        const updateResponse = await Server.Compensation.updateCompensation(
          __compensation.id,
          selectedCompensation.value
        );

        if (updateResponse.status === 200) {
          notification.success({ message: t('edit.success') });
        }
      } else {
        const createResponse = await Server.Compensation.createCompensation({
          lecturerPin: userPin,
          activityType: selectedCompensation.type,
          compensation: selectedCompensation.value,
        });

        if (createResponse.status === 201) {
          notification.success({ message: t('new.success') });
        }
      }
      await fetchCompensation();
    }
    if (edit.bool) {
      setEdit({ key: '', bool: false });
    }

    setLoading(false);
  };

  return (
    <Card loading={loading} className='shadow-sm'>
      <Flex className='mb-1'>
        <Tooltip title={t('addCompensation')}>
          <NewButton onClick={() => setAddCompensationModal(true)} />
        </Tooltip>
      </Flex>
      <Table
        loading={loading}
        dataSource={compensations}
        columns={columns}
        pagination={false}
        scroll={{
          x: 800,
        }}
        rowKey={(val) => val.id}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      ></Table>

      <Modal
        open={addCompensationModal}
        title={t('addCompensation')}
        onOk={() => {
          submitCompensation();
          setAddCompensationModal(false);
        }}
        okButtonProps={{ ghost: true }}
        onCancel={() => setAddCompensationModal(false)}
        okText={t('common:save')}
        cancelText={t('common:cancel')}
      >
        <Form.Item label={t('type')}>
          <Select
            defaultValue={selectedCompensation.type}
            onChange={(e: EventType) => {
              if (
                compensations.some(
                  (compensation) => compensation.activityType === e
                )
              ) {
                const _compensation = compensations.find(
                  (compensation) => compensation.activityType === e
                );
                setSelectedCompensation({
                  type: e,
                  value: _compensation!.compensation,
                });
              } else {
                setSelectedCompensation({ type: e, value: 0 });
              }
            }}
            options={compensationTypeArray.current.map((type) => {
              return { key: type, value: type, label: t(`types.${type}`) };
            })}
          />
        </Form.Item>
        <Form.Item label={t('value')}>
          <InputNumber
            addonAfter={`€/${t('school_hour')}`}
            min={0}
            value={selectedCompensation.value}
            decimalSeparator=','
            onChange={(e) => {
              if (e) {
                setSelectedCompensation((prevVal) => ({
                  ...prevVal,
                  value: e,
                }));
              }
            }}
          />
        </Form.Item>
      </Modal>
    </Card>
  );
}
