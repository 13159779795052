import {
  Button,
  Card,
  Flex,
  Form,
  Input,
  Select,
  Switch,
  notification,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Server } from '../../../../api/server-index';
import { PartnerDto } from '../../../../types/dto/partner.dto';
import { PartnerType } from '../../../../types/enum/partner-type';
import BackButton from '../../../common/buttons/back-button.component';
import { formItemLayoutLG } from '../../../layout/form/form-item.layout';

const EditPartner = () => {
  const { t } = useTranslation(['partner', 'common']);
  const [form] = useForm<PartnerDto>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showIntl, setShowIntl] = useState<boolean>(false);
  const [partner, setPartner] = useState<PartnerDto>();
  const navigate = useNavigate();
  const params = useParams();
  const partnerPin = params.partnerPin;

  const fetchPartner = useCallback(async () => {
    if (partnerPin) {
      const response = await Server.Partner.getPartnerByPin(partnerPin);
      setPartner(response);
      if (response.intlBankAccountNr) {
        setShowIntl(true);
      }
      form.setFieldsValue(response);
    }
  }, [form, partnerPin]);

  useEffect(() => {
    fetchPartner();
  }, [fetchPartner]);

  const submitPartner = async (vals: PartnerDto) => {
    setLoading(true);
    if (partner) {
      const createResponse = await Server.Partner.updatePartner(
        partner.pin,
        vals
      );
      if (createResponse.status === 200) {
        notification.success({ message: t('edit.success') });
        navigate(-1);
      }
    }

    setLoading(false);
  };

  return (
    <Card loading={loading} className='shadow-sm'>
      <BackButton onClick={() => navigate(-1)} />
      <Flex gap={18} align='center'>
        <h3>{t('edit.title')}</h3>
      </Flex>
      <Form form={form} onFinish={submitPartner} {...formItemLayoutLG}>
        <Form.Item name={'pin'} label={t('form.pin')}>
          <Input disabled />
        </Form.Item>
        <Form.Item name={'type'} label={t('form.type')}>
          <Select
            options={Object.values(PartnerType).map((type) => {
              return { key: type, value: type, label: t(`types.${type}`) };
            })}
          />
        </Form.Item>
        <Form.Item name={'name'} label={t('form.name')}>
          <Input />
        </Form.Item>
        <Form.Item name={'iban'} label={t('form.iban')}>
          <Input />
        </Form.Item>
        <Form.Item name={'bankName'} label={t('form.bankName')}>
          <Input />
        </Form.Item>
        <Form.Item name={'address'} label={t('form.address')}>
          <Input />
        </Form.Item>
        <Form.Item name={'town'} label={t('form.town')}>
          <Input />
        </Form.Item>
        <Form.Item name={'postCode'} label={t('form.postCode')}>
          <Input />
        </Form.Item>
        <Form.Item name={'country'} label={t('form.country')}>
          <Input />
        </Form.Item>
        <Form.Item name={'phone'} label={t('form.phone')}>
          <Input />
        </Form.Item>
        <Form.Item name={'email'} label={t('form.email')}>
          <Input />
        </Form.Item>
        <Form.Item name={'website'} label={t('form.website')}>
          <Input />
        </Form.Item>
        <Flex align='center' gap={8}>
          <h4>{t('new.title_intl')}</h4>
          <Switch checked={showIntl} onChange={() => setShowIntl(!showIntl)} />
        </Flex>
        {showIntl ? (
          <>
            <Form.Item
              label={t('form.intlBankAccountNr')}
              name='intlBankAccountNr'
            >
              <Input />
            </Form.Item>
            <Form.Item label={t('form.intlBankSwift')} name='intlBankSwift'>
              <Input />
            </Form.Item>
            <Form.Item label={t('form.intlBankAdress')} name='intlBankAdress'>
              <Input />
            </Form.Item>
            <Form.Item label={t('form.intlBankTown')} name='intlBankTown'>
              <Input />
            </Form.Item>
            <Form.Item label={t('form.intlBankZip')} name='intlBankZip'>
              <Input />
            </Form.Item>
            <Form.Item label={t('form.intlBankCountry')} name='intlBankCountry'>
              <Input />
            </Form.Item>
          </>
        ) : (
          <></>
        )}
        <Form.Item>
          <Flex justify='flex-end'>
            {' '}
            <Button type='primary' ghost onClick={form.submit}>
              {t('common:save')}
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default EditPartner;
