import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  Button,
  Col,
  Descriptions,
  Flex,
  Modal,
  Popconfirm,
  Row,
  Spin,
  Tabs,
  notification,
} from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Server } from '../../../../api/server-index';
import { AuthContext } from '../../../../context/auth-context';
import { UserDto } from '../../../../types/dto/user.dto';
import {
  OtherExpensesDto,
  WorksheetDto,
  WorksheetEventDto,
} from '../../../../types/dto/worksheet.dto';
import { UserRoles } from '../../../../types/enum/user-roles';
import { WorkSheetStatus } from '../../../../types/enum/worksheet-status';
import { HasRoles } from '../../../../utils/has-roles';
import BackButton from '../../../common/buttons/back-button.component';
import { Invoice } from '../invoice-pdf/invoice';
import { WorkSheetPdfDisplay } from '../invoice-pdf/invoice-pdf-display';
import WorksheetDetails from '../worksheet-details/worksheet-details.component';
import OtherExpenses from './other-expenses/other-expenses.component';
import WorksheetEvents from './worksheet-events/worksheet-events.component';
import WorksheetMeta from './worksheet-meta/worksheet-meta.component';
import WorksheetNotes from './worksheet-notes/worksheet-notes.component';

const EditWorksheet = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const worksheetId = params.worksheetId;
  const { t } = useTranslation(['worksheets', 'common']);
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [open, setModal] = useState<boolean>(false);
  const [worksheet, setWorksheet] = useState<WorksheetDto>();
  const [wsOwner, setWsOwner] = useState<UserDto>();
  const [wsEvents, setWsEvents] = useState<WorksheetEventDto[]>([]);
  const [wsOtherExpenses, setWsOtherExpenses] = useState<OtherExpensesDto[]>(
    []
  );
  const [rejectModalOpen, setRejectModalOpen] = useState<boolean>(false);

  const fetchWorksheet = useCallback(async () => {
    setLoading(true);

    if (worksheetId) {
      const worksheetRes = await Server.Worksheets.getWorksheet(worksheetId);
      setWorksheet(worksheetRes);

      const __user = await Server.User.getUser(worksheetRes.employeePin);
      setWsOwner(__user);
    } else {
      notification.error({ message: t('edit.not_available') });
      navigate(-1);
    }

    setLoading(false);
  }, [navigate, t, worksheetId]);

  useEffect(() => {
    fetchWorksheet();
    setReload(false);
  }, [fetchWorksheet, reload]);

  const calculateTotalWorkHours = () => {
    const totalHours = wsEvents.reduce((acc, wsEvent) => {
      if (wsEvent.duration) {
        return acc + parseFloat(wsEvent.duration);
      }

      return acc;
    }, 0);

    return totalHours;
  };

  const approveWorkSheet = async (worksheet: WorksheetDto) => {
    try {
      setLoading(true);
      switch (worksheet.status) {
        case WorkSheetStatus.IN_REVIEW_BY_ACCOUNTING:
          const accResponse =
            await Server.Worksheets.approveWorksheetByAccounting(worksheet.id);
          if (accResponse.status === 200) {
            notification.success({
              message: t('edit.actions.approve.success'),
            });
          }
          break;
        case WorkSheetStatus.IN_REVIEW_BY_PROGRAMME_COORDINATOR:
          const pcResponse =
            await Server.Worksheets.approveWorksheetByProgramCoordinator(
              worksheet.id
            );
          if (pcResponse.status === 200) {
            notification.success({
              message: t('edit.actions.approve.success'),
            });
          }
          break;
        default:
          break;
      }
      setReload(true);
    } finally {
      setLoading(false);
    }
  };

  const rejectWorkSheet = async (
    worksheet: WorksheetDto,
    finalize: boolean
  ) => {
    try {
      setLoading(true);

      switch (worksheet.status) {
        case WorkSheetStatus.IN_REVIEW_BY_ACCOUNTING:
          const accResponse =
            await Server.Worksheets.rejectWorksheetByAccounting(
              worksheet.id,
              finalize
            );
          if (accResponse.status === 200) {
            notification.success({ message: t('edit.actions.reject.success') });
          }
          break;
        case WorkSheetStatus.IN_REVIEW_BY_PROGRAMME_COORDINATOR:
          const pcResponse =
            await Server.Worksheets.rejectWorksheetByProgramCoordinator(
              worksheet.id,
              finalize
            );
          if (pcResponse.status === 200) {
            notification.success({ message: t('edit.actions.reject.success') });
          }
          break;
        default:
          break;
      }

      navigate('/worksheets');
    } finally {
      setLoading(false);
    }
  };

  const withdrawWorkSheet = async () => {
    setLoading(true);

    if (worksheet) {
      const response = await Server.Worksheets.withdrawWorksheet(
        worksheet.id,
        worksheet.status
      );

      if (response.status === 200) {
        notification.success({ message: t('edit.actions.withdraw.success') });
        setReload(true);
      }
    }

    setLoading(false);
  };

  const renderApproveRejectButtons = (worksheet: WorksheetDto) => {
    const ApproveRejectButtons = () => {
      return (
        <Flex gap={12}>
          <Popconfirm
            title={t('edit.actions.approve.confirm')}
            onConfirm={() => approveWorkSheet(worksheet)}
          >
            <Button type='primary' ghost>
              {t('list.approve')}
            </Button>
          </Popconfirm>
          <Button
            type='primary'
            danger
            ghost
            onClick={() => setRejectModalOpen(true)}
          >
            {t('list.reject')}
          </Button>
        </Flex>
      );
    };

    const WithdrawButton = () => {
      return (
        <Popconfirm
          title={t('edit.actions.withdraw.confirm')}
          onConfirm={() => withdrawWorkSheet()}
        >
          <Button danger ghost>
            {t('list.withdraw')}
          </Button>
        </Popconfirm>
      );
    };

    switch (worksheet.status) {
      case WorkSheetStatus.IN_REVIEW_BY_PROGRAMME_COORDINATOR:
        if (
          HasRoles(
            [UserRoles.PROGRAMME_COORDINATOR, UserRoles.ADMINISTRATOR],
            user
          )
        ) {
          return <ApproveRejectButtons />;
        }
        break;
      case WorkSheetStatus.IN_REVIEW_BY_ACCOUNTING:
        if (HasRoles([UserRoles.ACCOUNTING], user)) {
          return <ApproveRejectButtons />;
        } else if (HasRoles([UserRoles.PROGRAMME_COORDINATOR], user)) {
          return <WithdrawButton />;
        } else if (HasRoles([UserRoles.ADMINISTRATOR], user)) {
          return (
            <Flex gap={12}>
              <ApproveRejectButtons />
              <WithdrawButton />
            </Flex>
          );
        }
        break;
      case WorkSheetStatus.REJECTED_BY_ACCOUNTING:
        if (HasRoles([UserRoles.ACCOUNTING, UserRoles.ADMINISTRATOR], user)) {
          return <WithdrawButton />;
        }
        break;
      case WorkSheetStatus.REJECTED_BY_PROGRAMME_COORDINATOR:
        if (
          HasRoles(
            [UserRoles.PROGRAMME_COORDINATOR, UserRoles.ADMINISTRATOR],
            user
          )
        ) {
          return <WithdrawButton />;
        }
        break;
      default:
        return <></>;
    }
  };

  return (
    <Spin spinning={loading}>
      <BackButton href={`/worksheets${location.search}`} />
      {worksheet ? (
        <>
          <WorksheetDetails worksheet={worksheet} />
          <Row justify='end' gutter={[0, 16]} className='mt-1'>
            <Col>
              {worksheet ? <>{renderApproveRejectButtons(worksheet)}</> : <></>}
              {HasRoles(
                [UserRoles.ACCOUNTING, UserRoles.ADMINISTRATOR],
                user
              ) &&
              worksheet.status === WorkSheetStatus.APPROVED_BY_ACCOUNTING ? (
                <Button className='mt-1' onClick={() => setModal(true)}>
                  <EyeOutlined />
                  {t('pdf.view')}
                </Button>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Tabs
            items={[
              {
                key: 'events_and_expenses',
                label: t('edit.events.tab_title'),
                children: (
                  <>
                    <WorksheetEvents
                      worksheet={worksheet}
                      setWsEvents={setWsEvents}
                    />
                    <OtherExpenses
                      worksheet={worksheet}
                      setWsOtherExpenses={setWsOtherExpenses}
                      setWorksheetReload={setReload}
                    />
                    <Flex justify='end'>
                      <Descriptions className='mt-2' bordered column={1}>
                        <Descriptions.Item label={t('totalHours')}>
                          {calculateTotalWorkHours()}
                        </Descriptions.Item>
                        {HasRoles(
                          [UserRoles.ACCOUNTING, UserRoles.ADMINISTRATOR],
                          user
                        ) ? (
                          <Descriptions.Item
                            label={t('total').toUpperCase()}
                            className='fw-bold'
                          >
                            {worksheet?.totalAmount
                              ?.toFixed(2)
                              .replace('.', ',')}
                            €
                          </Descriptions.Item>
                        ) : (
                          <></>
                        )}
                      </Descriptions>
                    </Flex>
                  </>
                ),
              },
              {
                key: 'notes',
                label: t('edit.notes.title'),
                children: <WorksheetNotes worksheet={worksheet} />,
              },
              {
                key: 'meta',
                label: t('meta.title'),
                children: <WorksheetMeta />,
              },
            ]}
            defaultActiveKey='events_and_expenses'
            destroyInactiveTabPane
          />
          <Modal
            open={open}
            onCancel={() => setModal(false)}
            footer={
              <Row justify={'space-between'}>
                <PDFDownloadLink
                  className='ant-btn css-dev-only-do-not-override-33o72l ant-btn-primary mt-1'
                  fileName={`sheet-${worksheet.worksheetNr}`}
                  document={
                    <Invoice
                      worksheet={worksheet}
                      totalHours={calculateTotalWorkHours()}
                      worksheetEvents={wsEvents ? wsEvents : []}
                      otherExpenses={wsOtherExpenses ? wsOtherExpenses : []}
                      user={wsOwner}
                    />
                  }
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      <Flex gap={8}>
                        <DownloadOutlined />
                        {t('pdf.view')}
                      </Flex>
                    ) : (
                      <Flex gap={8}>
                        <DownloadOutlined />
                        {t('pdf.view')}
                      </Flex>
                    )
                  }
                </PDFDownloadLink>
                <Button onClick={() => setModal(false)} className='mt-1'>
                  {t('pdf.cancel')}
                </Button>
              </Row>
            }
            okButtonProps={{ ghost: true }}
            width={1100}
            okText={t('pdf.ok')}
            cancelText={t('pdf.cancel')}
            destroyOnClose
          >
            <Spin spinning={loading}>
              <WorkSheetPdfDisplay
                worksheet={worksheet}
                totalHours={calculateTotalWorkHours()}
                worksheetEvents={wsEvents ? wsEvents : []}
                otherExpenses={wsOtherExpenses ? wsOtherExpenses : []}
                user={wsOwner}
              />
            </Spin>
          </Modal>
        </>
      ) : (
        <></>
      )}
      <Modal
        open={rejectModalOpen}
        onCancel={() => setRejectModalOpen(false)}
        title={t('edit.actions.reject.title')}
      >
        {worksheet ? (
          <Flex vertical gap={12} className='mt-1'>
            <Button
              className='h-100'
              onClick={() => rejectWorkSheet(worksheet, false)}
            >
              <Flex vertical align='start'>
                <h3 className='m-0'>
                  {t('edit.actions.reject.reject_revert')}
                </h3>
                <span
                  style={{
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                    width: '100%',
                    textAlign: 'left',
                  }}
                >
                  {t('edit.actions.reject.reject_revert_desc')}
                </span>
              </Flex>
            </Button>
            <Button
              className='h-100'
              danger
              onClick={() => rejectWorkSheet(worksheet, true)}
            >
              <Flex vertical align='start'>
                <h3 className='m-0'>
                  {t('edit.actions.reject.reject_finalize')}
                </h3>
                <span
                  style={{
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                    width: '100%',
                    textAlign: 'left',
                  }}
                >
                  {t('edit.actions.reject.reject_finalize_desc')}
                </span>
              </Flex>
            </Button>
          </Flex>
        ) : (
          <></>
        )}
      </Modal>
    </Spin>
  );
};

export default EditWorksheet;
