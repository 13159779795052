import { Flex } from 'antd';
import { useContext } from 'react';

import { AuthContext } from '../../../context/auth-context';

interface Props {
  mobile: boolean;
}

const LanguageSwitcher = ({ mobile }: Props) => {
  const { changeLanguage } = useContext(AuthContext);

  return !mobile ? (
    <Flex align='center' justify='center'>
      <div className='language-switcher mb-1'>
        <div
          className='language-switcher-hr'
          onClick={() => changeLanguage('hr')}
        >
          🇭🇷
        </div>
        <div
          className='language-switcher-en'
          onClick={() => changeLanguage('en')}
        >
          🇬🇧
        </div>
      </div>
    </Flex>
  ) : (
    <Flex align='center' justify='center'>
      <div
        className='language-switcher-hr'
        onClick={() => changeLanguage('hr')}
      >
        🇭🇷
      </div>
      <div
        className='language-switcher-en'
        onClick={() => changeLanguage('en')}
      >
        🇬🇧
      </div>
    </Flex>
  );
};

export default LanguageSwitcher;
