import { InfoCircleFilled } from '@ant-design/icons';
import {
  Col,
  Empty,
  Flex,
  Input,
  Row,
  Select,
  Spin,
  Table,
  TableProps,
  Tag,
  Tooltip,
} from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from 'react-router-dom';

import { Server } from '../../../../api/server-index';
import { AuthContext } from '../../../../context/auth-context';
import { SearchDataSelection } from '../../../../types/common/search-data-selection.type';
import { PaginatedData } from '../../../../types/dto/common.dto';
import { ProgramDto } from '../../../../types/dto/event.dto';
import { SubjectDto } from '../../../../types/dto/subject.dto';
import { UserDto } from '../../../../types/dto/user.dto';
import { getTagClassName } from '../../../../utils/get-tag-class-name';
import { setNavigationPath } from '../../../../utils/navigation-params';
import BackButton from '../../../common/buttons/back-button.component';
import FilterIcon from '../../../common/icons/filter-icon.component';
import Subtitle from '../../../common/titles/subtitle.component';

const ProgramSubjects = () => {
  const params = useParams();
  const location = useLocation();
  const { language, currentAcademicYear } = useContext(AuthContext);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation('programs');
  const [program, setProgram] = useState<ProgramDto>();
  const [dataSelection, setDataSelection] = useState<SearchDataSelection>({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    pageSize: searchParams.get('pageSize')
      ? Number(searchParams.get('pageSize'))
      : 10,
    search: searchParams.get('search') ?? '',
  });
  const [academicYearOptions, setAcademicYearOptions] = useState<
    {
      label: string;
      value: number;
    }[]
  >();

  const [subjects, setSubjects] = useState<PaginatedData<SubjectDto[]>>();
  const [selectedAcademicYear, setSelectedAcademicYear] =
    useState<number>(currentAcademicYear);

  const fetchProgram = useCallback(async () => {
    if (params.programId) {
      setLoading(true);
      const response = await Server.Programs.getProgramById(params.programId);
      setProgram(response);
    }
  }, [params.programId]);

  const fetchAcademicYearOptions = useCallback(async () => {
    const academicYearResponse = await Server.Programs.getAcademicYearOptions(
      'subjectProgram'
    );
    const options = academicYearResponse.map((o) => ({
      label: o.toString(),
      value: o,
    }));
    setAcademicYearOptions(options);
  }, []);

  const fetchSubjects = useCallback(async () => {
    if (program) {
      setLoading(true);
      const response = await Server.Subjects.getSubjects({
        page: dataSelection.page,
        perPage: dataSelection.pageSize,
        programId: program.id,
        subjectName: dataSelection.search,
        academicYear: selectedAcademicYear,
      });

      setSubjects(response);
      setLoading(false);
    }
  }, [selectedAcademicYear, dataSelection, program]);

  useEffect(() => {
    fetchProgram();
    fetchAcademicYearOptions();
  }, [fetchAcademicYearOptions, fetchProgram]);

  useEffect(() => {
    fetchSubjects();
    setSearchParams(setNavigationPath({ selectedAcademicYear }, dataSelection));
  }, [selectedAcademicYear, dataSelection, fetchSubjects, setSearchParams]);

  const columns: TableProps<SubjectDto>['columns'] = [
    {
      key: 'subjectName',
      title: t('subjects.subjectName'),
      dataIndex: 'subjectName',
      width: '40%',
      filterDropdown: () => (
        <Input
          placeholder={t('filter.search')}
          value={dataSelection.search}
          onChange={(event) => {
            setDataSelection((prevVal) => ({
              ...prevVal,
              page: 1,
              search: event.target.value,
            }));
          }}
        />
      ),
      filterIcon: <FilterIcon count={dataSelection.search ? 1 : 0} />,
      render: (name, record) => (
        <span>
          {language === 'en'
            ? record.subjectNameEn ?? record.subjectName
            : record.subjectName}
        </span>
      ),
    },
    {
      key: 'lecturers',
      title: t('subjects.lecturers'),
      dataIndex: 'lecturers',
      render: (lecturers) => {
        return lecturers?.map((lecturer: UserDto, index: number) => {
          const isLast = index === lecturers.length - 1;
          return (
            <span key={lecturer.pin}>
              <Link to={`/users/${lecturer.pin}`}>
                <span className='m-0'>{`${lecturer.givenName} ${lecturer.familyName}`}</span>
              </Link>
              <span>{!isLast ? ', ' : ''}</span>
            </span>
          );
        });
      },
    },
    {
      key: 'info',
      title: t('subjects.moodle'),
      dataIndex: 'id',
      align: 'end',
      render: (id, record) => {
        if (record.numberOfMoodleCourses === 0) {
          return (
            <Tooltip title={t('subjects.moodleAlert0')}>
              <InfoCircleFilled style={{ color: '#ff4d4f' }} />
            </Tooltip>
          );
        } else if (record.numberOfMoodleCourses === 1) {
          return (
            <Tooltip title={t('subjects.moodleAlert1')}>
              <InfoCircleFilled style={{ color: '#faad14' }} />
            </Tooltip>
          );
        } else {
          return (
            <Tooltip
              title={t('subjects.moodleAlertMultiple', {
                number: record.numberOfMoodleCourses,
              })}
            >
              <InfoCircleFilled style={{ color: '#52c41a' }} />
            </Tooltip>
          );
        }
      },
    },
  ];

  return (
    <Spin spinning={loading}>
      <BackButton href={`/programs/${location?.search}`} />
      <Flex vertical>
        <Flex gap={4} className='mobile-vertical'>
          <Subtitle text={t('subjects.title')} />
          {program ? (
            <i>
              <Subtitle text={program.programName} />
            </i>
          ) : (
            <></>
          )}
        </Flex>
        {program ? (
          <div>
            <Tag className={getTagClassName(program.level)}>
              {t(`levels.${program.level}`)}
            </Tag>
            <Tag className={getTagClassName(program.mode)}>
              {t(`modes.${program.mode}`)}
            </Tag>
            <Tag>{t(`locations.${program.location}`)}</Tag>
          </div>
        ) : (
          <></>
        )}
      </Flex>
      <Row gutter={[12, 12]} className='mt-1'>
        <Col xs={6} sm={6} md={6} lg={4} xl={2}>
          <Select
            value={selectedAcademicYear}
            options={academicYearOptions}
            onChange={(value) => setSelectedAcademicYear(value)}
          />
        </Col>
      </Row>
      <Table
        className='mt-1'
        columns={columns}
        dataSource={subjects?.records}
        scroll={{
          x: 800,
        }}
        rowKey={(val) => val.id}
        pagination={{
          hideOnSinglePage: true,
          total: subjects?.totalCount,
          pageSize: dataSelection.pageSize,
          current: dataSelection.page,
          onChange: (page: number, pageSize?: number) => {
            const newPageSize = pageSize || dataSelection.pageSize;
            // Lets get back to first page on size change
            if (newPageSize !== dataSelection.pageSize) page = 1;

            setDataSelection({
              ...dataSelection,
              page,
              pageSize: newPageSize,
            });
            const node = document.querySelector('.ant-card-body');
            node?.scrollIntoView();
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
    </Spin>
  );
};

export default ProgramSubjects;
