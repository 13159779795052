import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';

import { UserDto } from '../../../../types/dto/user.dto';
import {
  OtherExpensesDto,
  WorksheetDto,
  WorksheetEventDto,
} from '../../../../types/dto/worksheet.dto';
import { CompanyInfo } from './components/company-info';
import { InvoiceTo } from './components/invoice-to';
import { InvoiceTable } from './components/table';

interface Props {
  worksheet: WorksheetDto;
  totalHours: number;
  worksheetEvents: WorksheetEventDto[];
  otherExpenses: OtherExpensesDto[];
  user?: UserDto;
}

export function Invoice({
  worksheet,
  totalHours,
  user,
  worksheetEvents,
  otherExpenses,
}: Props) {
  const { t } = useTranslation('pdf');

  Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf',
        fontWeight: 300,
      },
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
        fontWeight: 400,
      },
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-medium-webfont.ttf',
        fontWeight: 500,
      },
      {
        src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
        fontWeight: 600,
      },
    ],
  });

  const styles = StyleSheet.create({
    page: {
      margin: '20px auto',
      border: '1px solid #ccc',
      fontFamily: 'Roboto',
      padding: '20px',
      paddingBottom: 100,
      fontSize: 8,
    },
    logo: { height: 'auto', maxWidth: '100px' },
    headingContainer: {
      flexDirection: 'row',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderBottom: '1px solid #ccc',
      paddingBottom: '10px',
    },
    worksheetBody: {
      padding: '20px 0',
    },
    noteRowContainer: {
      marginTop: '20px',
      fontSize: 10,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    invoiceFooter: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: 40,
      borderTop: '1px solid #ccc',
      paddingTop: '10px',
      textAlign: 'center',
    },
    logoRowStart: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
  });

  return user ? (
    <Document>
      <Page size='A4' style={styles.page} wrap>
        <View style={styles.logoRowStart}>
          <Image source={'/assets/aspira-uciliste.png'} style={styles.logo} />
        </View>
        <View style={styles.headingContainer}>
          <CompanyInfo worksheet={worksheet} />
          <InvoiceTo user={user} />
        </View>
        <View style={styles.worksheetBody}>
          <InvoiceTable
            worksheetEvents={worksheetEvents}
            totalHours={totalHours}
            otherExpenses={otherExpenses}
            worksheet={worksheet}
          />
        </View>
        <View style={styles.noteRowContainer}>
          <Text>{t('note')}</Text>
        </View>
        <View style={styles.invoiceFooter} fixed>
          <Text>{t('copyright')}: Aspira SEPI © 2024</Text>
        </View>
      </Page>
    </Document>
  ) : (
    <></>
  );
}
