import { StyleSheet, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import {
  OtherExpensesDto,
  WorksheetEventDto,
} from '../../../../../../types/dto/worksheet.dto';

interface Props {
  events?: WorksheetEventDto[];
  otherExpenses?: OtherExpensesDto[];
}

export function TableRow({ events, otherExpenses }: Props) {
  const { t } = useTranslation('worksheets');
  const styles = StyleSheet.create({
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      borderBottom: '1px solid #e4e4e4',
      padding: '10px 0',
    },
    itemStyle: { flex: 1, padding: '0 10px' },
    textAlignStart: { textAlign: 'left' },
    textAlignCenter: { textAlign: 'center' },
    textAlignEnd: { textAlign: 'right' },
  });
  const eventRows = events?.map((item) => (
    <View style={styles.row} key={item.eventId.toString()}>
      <View style={[styles.itemStyle, styles.textAlignStart]}>
        <Text>
          {t(`edit.events.event_type.${item.eventType}`).toUpperCase()}
        </Text>
      </View>
      <View style={[styles.itemStyle, styles.textAlignCenter]}>
        <Text>{`${dayjs(item.dateStart).format('DD.MM.YYYY.')} (${dayjs(
          item.dateStart
        ).format('HH:mm')} - ${dayjs(item.dateEnd).format('HH:mm')})`}</Text>
      </View>
      <View style={[styles.itemStyle, styles.textAlignEnd]}>
        <Text>{item.duration}</Text>
      </View>
      <View style={[styles.itemStyle, styles.textAlignEnd]}>
        <Text>{item.totalAmount?.toFixed(2).replace('.', ',')} €</Text>
      </View>
    </View>
  ));

  const otherExpensesRows = otherExpenses?.map((item) => (
    <View style={styles.row} key={item.id.toString()}>
      <View style={[styles.itemStyle, styles.textAlignStart]}>
        <Text>{item.title}</Text>
      </View>
      <View style={[styles.itemStyle, styles.textAlignEnd]}>
        <Text>{item.totalAmount?.toFixed(2).replace('.', ',')} €</Text>
      </View>
    </View>
  ));
  return (
    <>
      <Fragment>{eventRows}</Fragment>
      <Fragment>{otherExpensesRows}</Fragment>
    </>
  );
}
