import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  EditOutlined,
} from '@ant-design/icons';
import {
  Button,
  Empty,
  Flex,
  InputNumber,
  notification,
  Table,
  TablePaginationConfig,
  TableProps,
  Tooltip,
} from 'antd';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../api/server-index';
import { AuthContext } from '../../../../../context/auth-context';
import { PaginatedData } from '../../../../../types/dto/common.dto';
import {
  CreateUpdateOtherExpenseDto,
  OtherExpenseAttachmentDto,
  OtherExpensesDto,
  WorksheetDto,
} from '../../../../../types/dto/worksheet.dto';
import { UserRoles } from '../../../../../types/enum/user-roles';
import { HasRoles } from '../../../../../utils/has-roles';

interface OtherExpensesProps {
  worksheet: WorksheetDto;
  setWsOtherExpenses: Dispatch<SetStateAction<OtherExpensesDto[]>>;
  setWorksheetReload: Dispatch<SetStateAction<boolean>>;
}

const OtherExpenses = (props: OtherExpensesProps) => {
  const { worksheet } = props;
  const { user } = useContext(AuthContext);
  const { t } = useTranslation('worksheets');
  const [reload, setReload] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [otherExpenses, setOtherExpenses] =
    useState<PaginatedData<OtherExpensesDto[]>>();
  const [editingExpenseId, setEditingExpenseId] = useState<string>();
  const [editData, setEditData] = useState<CreateUpdateOtherExpenseDto>();

  const fetchOtherExpenses = useCallback(async () => {
    setLoading(true);
    const response = await Server.Worksheets.getOtherExpenses({
      page: currentPage,
      pageSize: 10,
      worksheetId: worksheet.id,
    });
    setOtherExpenses(response);
    props.setWsOtherExpenses(response.records);
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, worksheet.id]);

  useEffect(() => {
    fetchOtherExpenses();
    setReload(false);
  }, [fetchOtherExpenses, reload]);

  const updateExpense = async (id: string) => {
    setLoading(true);

    if (editData) {
      const response = await Server.Worksheets.updateOtherExpense(id, editData);
      if (response.status === 200) {
        notification.success({
          message: t('edit.expenses.actions.success_update'),
        });
        setEditingExpenseId('');
        setReload(true);
        props.setWorksheetReload(true);
      }
    }

    setLoading(false);
  };

  const downloadAttachment = async (binary: OtherExpenseAttachmentDto) => {
    setLoading(true);
    try {
      const binaryData = await Server.Worksheets.getOtherExpenseAttachment(
        binary.id
      );

      const blob = new Blob([binaryData], { type: 'application/octet-stream' });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = binary.fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {}

    setLoading(false);
  };

  const columns: TableProps<OtherExpensesDto>['columns'] = [
    {
      key: 'title',
      title: t('edit.expenses.expense_title'),
      dataIndex: 'title',
      width: '20%',
      render: (title) => <span>{title}</span>,
    },
    {
      key: 'attachments',
      title: t('edit.expenses.attachments'),
      dataIndex: 'binaries',
      width: '20%',
      render: (binaries: OtherExpenseAttachmentDto[]) =>
        binaries.map((binary) => (
          <Flex key={binary.id} gap={12}>
            <span>{binary.fileName}</span>
            <DownloadOutlined onClick={() => downloadAttachment(binary)} />
          </Flex>
        )),
    },
  ];

  if (HasRoles([UserRoles.ACCOUNTING, UserRoles.ADMINISTRATOR], user)) {
    columns.push(
      {
        key: 'totalAmount',
        title: t('edit.expenses.total_amount'),
        dataIndex: 'totalAmount',
        width: '20%',
        render: (amount, record) => {
          if (editingExpenseId === record.id) {
            return (
              <InputNumber
                decimalSeparator=','
                addonAfter={'€'}
                placeholder={t('edit.expenses.expense_title')}
                defaultValue={record.totalAmount}
                min={0}
                onChange={(value) => {
                  if (value) {
                    setEditData({
                      title: record.title,
                      totalAmount: value,
                    });
                  }
                }}
              />
            );
          }
          return <span>{amount.toFixed(2).replace('.', ',')} €</span>;
        },
      },
      {
        key: 'actions',
        title: t('edit.expenses.actions.title'),
        dataIndex: 'id',
        width: '20%',
        align: 'right',
        render: (id) => (
          <>
            {editingExpenseId === id ? (
              <Flex gap={8} justify='end'>
                <Tooltip title={t('edit.expenses.actions.popconfirm.cancel')}>
                  <Button
                    onClick={() => {
                      setEditingExpenseId('');
                    }}
                  >
                    <CloseOutlined />
                  </Button>
                </Tooltip>

                <Tooltip title={t('edit.expenses.actions.popconfirm.ok')}>
                  <Button
                    loading={loading}
                    onClick={() => {
                      updateExpense(id);
                      setEditingExpenseId('');
                    }}
                  >
                    <CheckOutlined />
                  </Button>
                </Tooltip>
              </Flex>
            ) : (
              <Tooltip title={t('edit.expenses.actions.edit')}>
                <Button
                  loading={loading}
                  onClick={() => setEditingExpenseId(id)}
                >
                  <EditOutlined />
                </Button>
              </Tooltip>
            )}
          </>
        ),
      }
    );
  }

  const handlePageChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current || 1);
  };

  return (
    <div>
      <h4>{t('edit.expenses.title')}</h4>
      <Table
        rowKey={(val) => val.id}
        loading={loading}
        dataSource={otherExpenses?.records}
        columns={columns}
        scroll={{ x: 800 }}
        pagination={{
          total: otherExpenses?.totalCount,
          pageSize: 10,
          hideOnSinglePage: true,
        }}
        onChange={handlePageChange}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
    </div>
  );
};

export default OtherExpenses;
