import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  Button,
  Empty,
  Flex,
  notification,
  Popconfirm,
  Select,
  Spin,
  Table,
  TableProps,
  Tag,
} from 'antd';
import dayjs from 'dayjs';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import { Server } from '../../../../api/server-index';
import { AuthContext } from '../../../../context/auth-context';
import { SearchDataSelection } from '../../../../types/common/search-data-selection.type';
import { PaginatedData } from '../../../../types/dto/common.dto';
import { AnnualLeaveRequestDto } from '../../../../types/dto/work-logs.dto';
import { Location } from '../../../../types/enum/location';
import { RequestStatus } from '../../../../types/enum/request-status';
import { getTagClassName } from '../../../../utils/get-tag-class-name';
import { setNavigationPath } from '../../../../utils/navigation-params';
import NewButton from '../../../common/buttons/new-button.component';
import FilterIcon from '../../../common/icons/filter-icon.component';
import AnnualLeaveRequestPDF from './alr-pdf/annual-leave.request-pdf';
import NewAnnualLeaveRequest from './new-annual-leave-request/new-annual-leave-request.component';

interface Props {
  employeePin?: string;
}

const AnnualLeaveRequests = ({ employeePin }: Props) => {
  const { t } = useTranslation('work-logs');
  const { user } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const [reload, setReload] = useState<boolean>(false);
  const [dataSelection, setDataSelection] = useState<SearchDataSelection>({
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    pageSize: searchParams.get('pageSize')
      ? Number(searchParams.get('pageSize'))
      : 10,
  });
  const [annualLeaveRequests, setAnnualLeaveRequests] =
    useState<PaginatedData<AnnualLeaveRequestDto[]>>();
  const [filter, setFilter] = useState<{
    status: RequestStatus[];
    location?: Location;
  }>({
    status: searchParams.get('status')
      ? (searchParams.get('status')?.split(',') || []).map(
          (status) => status as RequestStatus
        )
      : [],
    location: searchParams.get('location')
      ? (searchParams.get('location') as Location)
      : undefined,
  });
  const [isNewModalOpen, setIsNewModalOpen] = useState<boolean>(false);

  const fetchAnnualLeaveRequests = useCallback(async () => {
    setLoading(true);
    if (user) {
      const userPin = employeePin ?? user.pin;
      const response = await Server.AnnualLeaveRequests.getAnnualLeaveRequests(
        userPin,
        {
          page: dataSelection.page,
          pageSize: dataSelection.pageSize,
          status: filter.status.length > 0 ? filter.status : undefined,
          location: filter.location ?? undefined,
        }
      );

      setAnnualLeaveRequests(response);
    }

    setLoading(false);
  }, [dataSelection, filter, user, employeePin]);

  useEffect(() => {
    fetchAnnualLeaveRequests();
    setSearchParams(setNavigationPath(filter, dataSelection), {
      replace: true,
    });
    setReload(false);
  }, [
    dataSelection,
    fetchAnnualLeaveRequests,
    filter,
    setSearchParams,
    reload,
  ]);

  const handleDeleteRequest = async (id: string) => {
    try {
      const response =
        await Server.AnnualLeaveRequests.deleteAnnualLeaveRequest(id);

      if (response.status === 200) {
        notification.success({ message: t('alr.delete.success') });
        setReload(true);
      }
    } catch (error) {
      return error;
    }
  };

  const handleApproveRequest = async (id: string) => {
    try {
      const response =
        await Server.AnnualLeaveRequests.approveAnnualLeaveRequest(id);

      if (response.status === 200) {
        notification.success({ message: t('alr.approve.success') });
        setReload(true);
      }
    } catch (error) {
      return error;
    }
  };

  const handleRejectRequest = async (id: string) => {
    try {
      const response =
        await Server.AnnualLeaveRequests.rejectAnnualLeaveRequest(id);

      if (response.status === 200) {
        notification.success({ message: t('alr.reject.success') });
        setReload(true);
      }
    } catch (error) {
      return error;
    }
  };

  const columns: TableProps<AnnualLeaveRequestDto>['columns'] = [
    {
      key: 'startDate',
      title: t('alr.start_date'),
      dataIndex: 'startDate',
      width: '20%',
      render: (startDate) => (
        <span>{dayjs(startDate).format('DD.MM.YYYY.')}</span>
      ),
    },
    {
      key: 'endDate',
      title: t('alr.end_date'),
      dataIndex: 'endDate',
      width: '20%',
      render: (endDate) => <span>{dayjs(endDate).format('DD.MM.YYYY.')}</span>,
    },
    {
      key: 'location',
      title: t('alr.location.title'),
      dataIndex: 'location',
      width: '20%',
      filterDropdown: () => (
        <Select
          allowClear
          className='w-100'
          placeholder={t('alr.location.title')}
          options={Object.values(Location).map((s) => ({
            value: s,
            label: t(`alr.location.${s}`),
          }))}
          onChange={(value) => {
            setFilter((prevData) => ({ ...prevData, location: value }));
            setDataSelection((prevVal) => ({
              ...prevVal,
              page: 1,
            }));
          }}
        />
      ),
      filterIcon: <FilterIcon count={filter.location ? 1 : 0} />,
      render: (location) => <span>{t(`alr.location.${location}`)}</span>,
    },
    {
      key: 'status',
      title: t('btr.status.title'),
      dataIndex: 'status',
      width: '20%',
      filterDropdown: () => (
        <Select
          className='filter-select'
          placeholder={t('btr.status.title')}
          mode='multiple'
          options={Object.values(RequestStatus).map((s) => ({
            value: s,
            label: t(`btr.status.${s}`),
          }))}
          onChange={(value) => {
            setFilter((prevData) => ({ ...prevData, status: [...value] }));
            setDataSelection((prevVal) => ({
              ...prevVal,
              page: 1,
            }));
          }}
        />
      ),
      filterIcon: <FilterIcon count={filter.status.length ?? 0} />,
      render: (status) => (
        <Tag className={getTagClassName(status)}>
          {t(`btr.status.${status}`)}
        </Tag>
      ),
    },
    {
      key: 'options',
      title: t('alr.options.title'),
      dataIndex: 'id',
      width: '20%',
      render: (id, record) => (
        <Flex gap={8}>
          <PDFDownloadLink
            className='ant-btn css-dev-only-do-not-override-33o72l ant-btn-primary'
            fileName={`GO_${dayjs(record.startDate).format('DD-MM')}`}
            document={<AnnualLeaveRequestPDF request={record} />}
          >
            {({ blob, loading, url, error }) =>
              loading ? (
                <Button>
                  <DownloadOutlined />
                </Button>
              ) : (
                <Button>
                  <DownloadOutlined />
                </Button>
              )
            }
          </PDFDownloadLink>

          {!employeePin ? (
            <Popconfirm
              title={t('alr.options.delete')}
              onConfirm={() => handleDeleteRequest(id)}
            >
              <Button danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          ) : record.status === RequestStatus.IN_REVIEW ? (
            <>
              <Popconfirm
                title={t('alr.options.approve')}
                onConfirm={() => handleApproveRequest(id)}
              >
                <Button>
                  <CheckOutlined />
                </Button>
              </Popconfirm>
              <Popconfirm
                title={t('alr.options.reject')}
                onConfirm={() => handleRejectRequest(id)}
              >
                <Button danger>
                  <CloseOutlined />
                </Button>
              </Popconfirm>
            </>
          ) : (
            <></>
          )}
        </Flex>
      ),
    },
  ];

  return (
    <Spin spinning={loading}>
      {!employeePin ? (
        <NewButton onClick={() => setIsNewModalOpen(true)} />
      ) : (
        <></>
      )}
      <Table
        rowKey={(val) => val.id}
        columns={columns}
        className='mt-1'
        dataSource={annualLeaveRequests?.records}
        scroll={{ x: 800 }}
        pagination={{
          total: annualLeaveRequests?.totalCount,
          hideOnSinglePage: true,
          pageSize: dataSelection.pageSize,
          current: dataSelection.page,
          onChange: (page: number, pageSize?: number) => {
            const newPageSize = pageSize || dataSelection.pageSize;
            // Lets get back to first page on size change
            if (newPageSize !== dataSelection.pageSize) page = 1;

            setDataSelection({
              ...dataSelection,
              page,
              pageSize: newPageSize,
            });
            const node = document.querySelector('.ant-card-body');
            node?.scrollIntoView();
          },
        }}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={t('common:no_data')}
            />
          ),
        }}
      />
      <NewAnnualLeaveRequest
        isNewModalOpen={isNewModalOpen}
        setIsNewModalOpen={setIsNewModalOpen}
        setReload={setReload}
      />
    </Spin>
  );
};

export default AnnualLeaveRequests;
