import { CloseOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Flex,
  notification,
  Popconfirm,
  Row,
  Table,
  TableProps,
  Tag,
} from 'antd';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Server } from '../../../../../api/server-index';
import { AuthContext } from '../../../../../context/auth-context';
import { ProgramDto } from '../../../../../types/dto/event.dto';
import { UserDto } from '../../../../../types/dto/user.dto';
import { ProgramLevel } from '../../../../../types/enum/program-level';
import { ProgramMode } from '../../../../../types/enum/program-mode';
import { getTagClassName } from '../../../../../utils/get-tag-class-name';
import { IsAdmin } from '../../../../../utils/has-roles';
import NewButton from '../../../../common/buttons/new-button.component';
import AssignProgramToCoordinator from './assign-program/assign-program-to-coordinator.component';

interface CoordinatedProgramsProps {
  loading: boolean;
  coordinator: UserDto;
  setReload: Dispatch<SetStateAction<boolean>>;
}

const CoordinatedPrograms = ({
  loading,
  coordinator,
  setReload,
}: CoordinatedProgramsProps) => {
  const { t } = useTranslation(['programs']);
  const { user, language } = useContext(AuthContext);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const removeAsCoordinator = async (programId: string) => {
    const response = await Server.Programs.removeProgramCoordinator(
      coordinator.pin,
      programId
    );

    if (response.status === 200) {
      notification.success({ message: t('coordinator.remove.success') });
      setReload(true);
    }
  };

  const columns: TableProps<ProgramDto>['columns'] = [
    {
      key: 'programName',
      title: t('columns.program_name'),
      dataIndex: 'programName',
      width: '40%',
      render: (name, record) => (
        <span>
          {language === 'en'
            ? record.programNameEn ?? record.programName
            : record.programName}
        </span>
      ),
    },
    {
      key: 'level',
      title: t('columns.level'),
      dataIndex: 'level',
      width: '10%',
      align: 'center',
      render: (level) => (
        <Tag className={getTagClassName(level as ProgramLevel)}>
          {t(`levels.${level}`)}
        </Tag>
      ),
    },
    {
      key: 'mode',
      title: t('columns.mode'),
      dataIndex: 'mode',
      width: '15%',
      align: 'center',
      render: (mode) => (
        <Tag className={getTagClassName(mode as ProgramMode)}>
          {t(`modes.${mode}`)}
        </Tag>
      ),
    },
    {
      key: 'location',
      title: t('columns.location'),
      dataIndex: 'location',
      width: '10%',
      align: 'center',
      render: (location) => <Tag>{t(`locations.${location}`)}</Tag>,
    },
  ];

  if (user && IsAdmin(user)) {
    columns.push({
      key: 'actions',
      title: t('columns.actions'),
      align: 'center',
      dataIndex: 'id',
      width: '5%',
      render: (id) => (
        <Popconfirm
          title={t('coordinator.tooltip.remove')}
          onConfirm={() => removeAsCoordinator(id)}
          okText={t('common:yes')}
          cancelText={t('common:no')}
        >
          <Button danger>
            <CloseOutlined />
          </Button>
        </Popconfirm>
      ),
    });
  }

  return (
    <Card loading={loading} className='shadow-sm'>
      {coordinator?.coordinatedPrograms &&
      coordinator.coordinatedPrograms.length > 0 ? (
        <>
          <Flex className='mb-1'>
            {user && IsAdmin(user) ? (
              <NewButton onClick={() => setIsModalOpen(true)} />
            ) : (
              <></>
            )}
          </Flex>
          <Table
            rowKey={(val) => val.id}
            scroll={{ x: 800 }}
            size='small'
            dataSource={coordinator.coordinatedPrograms}
            pagination={{ hideOnSinglePage: true }}
            columns={columns}
          />
        </>
      ) : (
        <Row align='middle' justify='space-between' gutter={[0, 8]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <span>{t('coordinator.no_programs')}. </span>
          </Col>
          {user && IsAdmin(user) ? (
            <Flex align='end'>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <Button onClick={() => setIsModalOpen(true)}>
                  {t('coordinator.assign.assign')}
                </Button>
              </Col>
            </Flex>
          ) : (
            <></>
          )}
        </Row>
      )}
      {user && IsAdmin(user) ? (
        <AssignProgramToCoordinator
          coordinator={coordinator}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          setReload={setReload}
        />
      ) : (
        <></>
      )}
    </Card>
  );
};

export default CoordinatedPrograms;
