import { Spin, TabsProps, Tabs, Card } from 'antd';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { Server } from '../../../../api/server-index';
import { AuthContext } from '../../../../context/auth-context';
import { UserDto } from '../../../../types/dto/user.dto';
import { UserRoles } from '../../../../types/enum/user-roles';
import { HasRoles } from '../../../../utils/has-roles';
import BackButton from '../../../common/buttons/back-button.component';
import MainTitle from '../../../common/titles/main-title.component';
import Subtitle from '../../../common/titles/subtitle.component';
import AnnualLeaveRequests from '../../work-logs-and-requests/annual-leave-requests/annual-leave-requests.component';
import BusinessTripRequests from '../../work-logs-and-requests/business-trip-requests/business-trip-requests.component';
import WorkLogs from '../../work-logs-and-requests/work-logs/work-logs.outlet';
import { EditUser } from './edit-user/edit-user.component';
import { Compensation } from './lecturers/compensation.component';
import { EditPartner } from './lecturers/edit-partner.component';
import LecturerSubjects from './lecturers/lecturer-subjects.component';
import CoordinatedPrograms from './program-coordinator/coordinated-programs.component';
import EnrolmentList from './student/enrolment-list.component';

interface Props {
  editUser: boolean;
  viewEditPartner: boolean;
}

export function User({ viewEditPartner }: Props) {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { userPin } = useParams<{ userPin: string }>();
  const [viewedUser, setViewedUser] = useState<UserDto>();
  const [loading, setLoading] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const { t } = useTranslation('user');

  const fetchUser = useCallback(async () => {
    window.scrollTo(0, 0);
    if (userPin) {
      setLoading(true);
      const _user = await Server.User.getUser(userPin);

      setViewedUser(_user);
      setLoading(false);
    }
  }, [userPin]);

  useEffect(() => {
    fetchUser();
    setReload(false);
  }, [fetchUser, reload]);

  const tabItems: TabsProps['items'] = [
    {
      key: 'editUser',
      label: t('user_info'),
      children: viewedUser ? (
        <EditUser user={viewedUser} editUser setReload={setReload} />
      ) : (
        <></>
      ),
    },
  ];

  if (viewedUser && HasRoles([UserRoles.PROGRAMME_COORDINATOR], viewedUser)) {
    tabItems.push({
      key: 'coordinatorInfo',
      label: t('coordinator_info'),
      children: (
        <CoordinatedPrograms
          loading={loading}
          coordinator={viewedUser}
          setReload={setReload}
        />
      ),
    });
  }

  if (viewedUser && HasRoles([UserRoles.STUDENT], viewedUser)) {
    tabItems.push({
      key: 'studentInfo',
      label: t('student_info'),
      children: <EnrolmentList user={viewedUser} />,
    });
  }

  if (viewedUser && HasRoles([UserRoles.LECTURER], viewedUser)) {
    tabItems.push({
      key: 'lecturerInfo',
      label: t('lecturer_info'),
      children: <LecturerSubjects user={viewedUser} />,
    });

    if (viewEditPartner) {
      tabItems.push(
        {
          key: 'partnerInfo',
          label: t('partner_info'),
          children: <EditPartner />,
        },
        {
          key: 'compensationInfo',
          label: t('compensation_info'),
          children: <Compensation />,
        }
      );
    }
  }

  if (
    HasRoles([UserRoles.ACCOUNTING, UserRoles.ADMINISTRATOR], user) &&
    HasRoles(
      [
        UserRoles.LECTURER,
        UserRoles.ACCOUNTING,
        UserRoles.ADMINISTRATOR,
        UserRoles.PROGRAMME_COORDINATOR,
        UserRoles.STAFF,
      ],
      viewedUser
    ) &&
    viewedUser?.isEmployee
  ) {
    tabItems.push(
      {
        key: 'workLogs',
        label: t('work_logs'),
        children: (
          <Card className='shadow-sm'>
            <WorkLogs employeePin={viewedUser?.pin} />
          </Card>
        ),
      },
      {
        key: 'btr',
        label: t('business_trip_requests'),
        children: (
          <Card className='shadow-sm'>
            <BusinessTripRequests employeePin={viewedUser?.pin} />
          </Card>
        ),
      },
      {
        key: 'alr',
        label: t('annual_leave_requests'),
        children: (
          <Card className='shadow-sm'>
            <AnnualLeaveRequests employeePin={viewedUser?.pin} />
          </Card>
        ),
      }
    );
  }

  return (
    <Spin spinning={loading}>
      <BackButton onClick={() => navigate(-1)} />
      {viewedUser ? (
        <>
          <MainTitle
            className='mt-1 mb-0'
            text={`${viewedUser.givenName} ${viewedUser.familyName}`}
          />
          <i>
            <Subtitle className='m-0' text={viewedUser.email} />
          </i>
        </>
      ) : (
        <></>
      )}
      <Tabs
        defaultActiveKey='userInfo'
        items={tabItems}
        destroyInactiveTabPane
      />
    </Spin>
  );
}
